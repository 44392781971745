import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types';
import get from 'lodash/get'
import Layout from '../components/layout'

class HomeIndex extends React.PureComponent {
  render() {
    return (
      <Layout>
        <div>
          Coming soon.
          A simple and fun distribution manufacturing service for the post-internet age.

          Islomade.com
        </div>
          </Layout>
    )
  }
}
export default HomeIndex
